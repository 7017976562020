import React from "react";

export default function Footer() {
  return (
    <footer>
      <div>
        <a>
          <span></span>
        </a>
      </div>
      <div>{/* ... Empty content ... */}</div>
      <hr />
      <div>
        <span></span>
        <div>{/* ... Empty content ... */}</div>
      </div>
    </footer>
  );
}
